import FacialFeaturesTemplate from '@/modules/questionnaire/components/steps/common/identity/facial-features/FacialFeaturesTemplate';

export default {
  title: 'Steps/Identity/FacialFeatures/FacialFeaturesTemplate',
  component: FacialFeaturesTemplate
};

const createStory = props => () => ({
  components: { FacialFeaturesTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><facial-features-template v-bind="$options.storyProps" /></div>'
});

export const FemaleBrownEyeDarkBlonde = createStory({
  gender: 'female',
  eyeColor: 'brown',
  naturalHairColor: 'dark_blonde'
});
